const NAME = 'matreso-landingpage-visited'
const _document = typeof document !== 'undefined' && document


/* create a cookie that expire in 365 days */
export function setCookie() {
  var d = new Date();
  d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  if (_document) {
    _document.cookie = NAME + "=true;" + expires + ";path=/;samesite=strict";
  }
}

export function getCookie() {
  if (_document) {
    var name = NAME + "=";
    var decodedCookie = decodeURIComponent(_document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return "";
}
